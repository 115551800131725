import React, {useContext, useState, useEffect, useRef} from 'react';
import TrackList from './TrackList'
import withRouter from 'components/helpers/withRouter';
import {WidgetHead} from 'components/common/DisplayElems';
import {TracksContext} from 'context/TracksContext';
import {BounceLoader} from "react-spinners";
import {useDropzone} from 'react-dropzone';

function AddBanner() {
    const { isBannerAdding, isBannerAdded, createBanner, removeBannerTrack, bannerTracks} = useContext(TracksContext);
    const [isBannerAddedd, setIsBannerAddedd] = useState(isBannerAdded);
    const [bannerImage, setBannerImage] = useState('');
    const [banner, setBanner] = useState('');
    const [bannerOrder, setBannerOrder] = useState(1);
    const [endDate, setEndDate] = useState('');
    const [title, setTitle] = useState('');
    const [bannerType, setBannerType] = useState('tracks');
    const [bannerUrl, setBannerUrl] = useState('');
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (isBannerAdded === true) {
            setIsBannerAddedd('Banner added successfully');
           
            setTimeout(() =>{
                window.location.href = '/banner/all';
              }, 3000);
        }
    }, [isBannerAdded]);
    const handelRemoveTrack = (track) => {
        removeBannerTrack(track)
    }
    // Function to get today's date in YYYY-MM-DD format
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();

        // Ensure month and day are two digits
        if (month < 10) {
            month = `0${month}`;
        }
        if (day < 10) {
            day = `0${day}`;
        }

        return `${year}-${month}-${day}`;
    };
    // Get current date in YYYY-MM-DD format
    const currentDate = getCurrentDate();
    const [startDate, setStartDate] = useState(currentDate);
    const handleSubmit = (e) => {
        e.preventDefault();
        // Construct the data object to send to API
        const formData = {
          banner,
          startDate,
          endDate,
          title,
          bannerOrder,
          bannerType,
          bannerUrl,
          targetBlank,
          resetForm
        };
    
        // Call API submission function from context
        createBanner(formData);
      };
    const onDrop = (acceptedFiles) => {
        // Handle dropped files
        console.log('Accepted files:', acceptedFiles);
        // Assuming you want to set the first accepted file as banner
        if (acceptedFiles.length > 0) {
            setBanner(acceptedFiles[0]);
            const reader = new FileReader();
            reader.onloadend = () => {
                setBannerImage(reader.result);
            };
            reader.readAsDataURL(acceptedFiles[0]);
        }
    };
    const { getInputProps } = useDropzone({
        onDrop: onDrop
    });
    const resetForm = () => {
        resetFileInput();
        setStartDate('');
        setEndDate('');
        setTitle('');
        setBanner('');
        setBannerImage('');
        setBannerOrder(1);
        setBannerType('tracks');
        setBannerUrl('');
    };
    const resetFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset file input value
        }
    };
    useEffect(() => {
        if (isBannerAdding === true) {
            //getTracks(fields);
        }
    }, [isBannerAdding]);

    const handleBannerOrderChange = (bannerOrder) => {
        if(bannerOrder > 0){
            setBannerOrder(bannerOrder);
        }else{
            setBannerOrder(1);
        }
    }
    const [targetBlank, setTargetBlank] = useState(false);
    return <><div className="col-xl-12">
        {isBannerAdding && <>
            <div className='loading'>
                <div className='loader'>
                    <BounceLoader loading={true} size={60} color='#34bfa3'/>
                    <span className="loading-text">Loading...</span>
                </div>
            </div>
        </>}
        <div className="m-portlet">
            <WidgetHead label="Add Banner"/>
                {isBannerAdded && <>
                    <div className='loading'>
                        <div className='loader'>
                            <span className="loading-text">{isBannerAddedd}</span>
                        </div>
                    </div>
                </>}
            <div className="d-flex m-3 p-1 pt-4 pb-4 banner-form">
                    <div className="col-xs-12 col-sm-12 col-lg-12 padding-remove">
                        <div className="row">
                            <div className="mb-0 form-group field-bannerform-banner_name required has-success col-xs-12 col-sm-4 col-lg-3">
                                <label className="padding-remove control-label">Banner Title</label>
                                <div className="">
                                    <input type="text" className="form-control form-control-edit" placeholder="Banner Title" value={title} onChange={(e) => setTitle(e.target.value)}/>
                                    <div className="col-lg-12 padding-remove">
                                        <p className="help-block help-block-error"></p>
                                    </div>
                                </div>
                                <div className="field-bannerform-start_time required">
                                    <label className="padding-remove control-label">Start Date</label>
                                    <div className="">
                                        <input type="date" className="form-control form-control-edit" placeholder="Start date" min={currentDate} max={endDate} value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
                                        <div className="col-lg-12 padding-remove">
                                            <p className="help-block help-block-error"></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="field-bannerform-start_time required">
                                    <label className="padding-remove control-label">Banner Order</label>
                                    <div className="">
                                        <input type="number" step="1" className="form-control form-control-edit" placeholder="Banner Order" min="1" value={bannerOrder} onChange={(e) => handleBannerOrderChange(e.target.value)}/>
                                        <div className="col-lg-12 padding-remove">
                                            <p className="help-block help-block-error"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-4 col-lg-3">
                                <div className="form-group field-bannerpictureupload-bannerimage">
                                    <div className="">
                                        <div className="file-upload">
                                            <div className="file-select">
                                                <label className="file-select-button" id="fileName">Choose File</label>
                                                <input 
                                                    {...getInputProps()} 
                                                    style={{display: 'block', padding: '9px'}} 
                                                    className="form-control form-control-edit"
                                                    placeholder="Banner Title"
                                                    accept="image/*"
                                                    ref={fileInputRef}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group field-bannerform-end_time required">
                                    <label className="padding-remove control-label">End Date</label>
                                    <div className="">
                                        <input type="date" className="form-control form-control-edit" placeholder="End date" min={startDate} value={endDate}  onChange={(e) => e.target.value >= startDate && setEndDate(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="form-group field-bannerform-end_time required">
                                    <label className="padding-remove control-label">Select Type</label>
                                    <div className="row mt-1">
                                        <div className="col-lg-3 col-12">
                                            <div class="form-check">
                                            <input 
                                                type="radio" 
                                                className="form-check-input" 
                                                value="url" 
                                                name="bannerType" 
                                                checked={bannerType === 'url'} 
                                                onChange={() => setBannerType('url')} 
                                            /> 
                                                <label className="form-check-label">URL</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="form-check">
                                                <input type="radio" className="form-check-input" checked={bannerType === 'tracks'} value="tracks" name='bannerType' onChange={() => setBannerType('tracks')}/>
                                                <label className="form-check-label">Tracks</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-4 col-lg-4">
                                {bannerImage && (
                                    <div>
                                    <img src={bannerImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '400px' }} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12" key={'_bannerTracks'}>
                            {bannerType === 'url' && (
                                <>
                                <div className='row'>
                                    <div className='col-lg-3 mb-4'>
                                        <label className="padding-remove control-label">Banner URL</label>
                                        <div className="">
                                            <input 
                                                type="url" 
                                                className="col-12 form-control form-control-edit" 
                                                placeholder="Banner URL" 
                                                value={bannerUrl} 
                                                onChange={(e) => setBannerUrl(e.target.value)} 
                                            />
                                        </div>
                                    </div>

                                    {/* Radio buttons for target="_blank" */}
                                    <div className="col-lg-3 form-group field-bannerform-target required">
                                        <label className="padding-remove control-label">Open in New Tab</label>
                                        <div>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="form-check">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            id="targetBlankYes"
                                                            name="targetBlank"
                                                            value="true"
                                                            onChange={() => setTargetBlank(true)}
                                                        />
                                                        <label className="form-check-label" htmlFor="targetBlankYes">
                                                            Yes
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-check">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            id="targetBlankNo"
                                                            name="targetBlank"
                                                            value="false"
                                                            onChange={() => setTargetBlank(false)}
                                                            defaultChecked
                                                        />
                                                        <label className="form-check-label" htmlFor="targetBlankNo">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </>
                            )}
                            {bannerType === 'tracks' && <>
                                <div className="d-flex col-12 flex-wrap mb-4" key={'_bannerTracksBtn'}>
                                    {bannerTracks && bannerTracks.length > 0 && bannerTracks.map((track) => (
                                        <div className="button-container" key={track.id+'_bannerTracks'}>
                                            <button className="close-button" key={track.id+'_bannerTracksBtn'}>{track.songName} <span className="close-icon" onClick={() => handelRemoveTrack(track)} key={track.id+'_bannerTracksBtnX'}>×</span></button>
                                        </div>
                                    ))}
                                </div>
                            </>}
                                <div className="form-group col-lg-12 px-0" style= {{ marginBottom: '0' }}>
                                    <div className="">
                                        <button className="btn btn-sm btn-primary blue-button" disabled={((bannerUrl.length === 0 && bannerType === 'url') || (bannerTracks.length === 0 && bannerType === 'tracks') || startDate.length === 0 || endDate.length === 0  || title.length === 0 || bannerImage.length === 0)} type="submit" onClick={handleSubmit}>
                                            <strong>Add banner</strong>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {bannerType === 'tracks' &&
                    <TrackList />
                }
            </div>
            </div>
        </>
}

export default withRouter(AddBanner);
