import React, { useContext, useState, useEffect } from 'react';
import { TracksContext } from 'context/TracksContext';
import {BounceLoader} from "react-spinners";
import {Paginator} from 'components/common/ControlElems';
import UpdateBanner from './UpdateBanner';
export default function Bannerlist(params) {
    const { setBannerWidgetHeadLabel } = params;
    const { fetchBannerTracks, resetUpdateBannerTrack, isBannerFetching, isBannerReAdding, isBannerReAdded, reUploadlBanner, setBannerOrder, banners, bannersCount, fetchBanners, setBannerStatus, isBannerUpdatingRes, isBannerUpdating, updateBannerUrl } = useContext(TracksContext);
    const [allBanners, setAllBanners] = useState([]);
    const [isBannerReAddedd, setIsBannerReAddedd] = useState(isBannerReAdded);
    const [bannerUpdating, setBannerUpdating] = useState(false);
    const [offset, setOffset] = useState(0);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupVisibleBannerReUpload, setPopupVisibleBannerReUpload] = useState(false);
    const [bannerId, setBannerId] = useState();
    const [bannerOrderr, setBannerOrderr] = useState(1);
    const [isValidRequest, setIsValidRequest] = useState(true);
    const [popupVisibleBannerUrlUpdate, setPopupVisibleBannerUrlUpdate] = useState(false); // New popup for URL update
    const [bannerUrl, setBannerUrl] = useState(''); // New state for banner URL
    const [targetBlank, setTargetBlank] = useState(false); // New state for targetBlank
    const [isBannerTracksUpdating, setIsBannerTracksUpdating] = useState(false);
    const [isBannerTracksUpdatingInit, setIsBannerTracksUpdatingInit] = useState(false);
    const [bannerTracksUpdating, setBannerTracksUpdating] = useState(false);
    useEffect(() => {
        if (isBannerReAdded === true) {
            setIsBannerReAddedd('Banner added successfully');
            setTimeout( async () =>{
                // window.location.href = '/banner/all';
                setOffset(0);
                await fetchBanners({offset: 0});
              }, 3000);
        }
    }, [isBannerReAdded]);

    // Function to get today's date in YYYY-MM-DD format
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();

        // Ensure month and day are two digits
        if (month < 10) {
            month = `0${month}`;
        }
        if (day < 10) {
            day = `0${day}`;
        }

        return `${year}-${month}-${day}`;
    };
    // Get current date in YYYY-MM-DD format
    const currentDate = getCurrentDate();
    const [bannerReUpload, setBannerReUpload] = useState({id: 0, start: currentDate, bannerOrder: 1, end: ''});

    const offsetOnChange = async (offset) => {
        setOffset(offset);
        //console.log(offset);
        await fetchBanners({offset});
    }

    useEffect(() => {
        const getBanners = async () => {
            if (!banners) {
                await fetchBanners({offset});
            }
        };
        getBanners();
    }, ['', banners]);

    useEffect(() => {
        if (isBannerUpdating) {
            setBannerUpdating(true);
        } else {
            setTimeout(function(){
                setBannerUpdating(false);
            },3000);
        }
    }, [isBannerUpdating]);

    useEffect(() => {
        if (banners && banners.length > 0) {
            setAllBanners(banners);
        }
    }, [banners]);

    const handleSwitcherClick = (id) => {
        const banner = allBanners.find(banner => {
            if (banner.id === id) {
                return banner;
            }
            //return banner;
        });
        setBannerStatus(banner);
        setBannerUpdating(true);        
        setOffset(0);
    };

    const handleOrderChangeClick = ({id, order}) => {
        order = order > 0 ? order : 1;
        setPopupVisible(true);
        setBannerOrderr(order);
        setBannerId(id);
        setOffset(0);
    }

    const handleBannerOrderUpdateClick = async()=> {
        await setBannerOrder({ id: bannerId, bannerOrder: bannerOrderr });
        setOffset(0);
    };

    const handleCloseModalClick = () => {
        setPopupVisible(false);
        setPopupVisibleBannerReUpload(false);
        setPopupVisibleBannerUrlUpdate(false); 
    }

    const isValidDate = (dateStr) => {
        const date = new Date(dateStr);
        return date instanceof Date && !isNaN(date.getTime()); // Valid date check
    };

    const isExpired = (banner) =>{
        const {end} = banner;
        // Validate start and end dates
        if (!end) {
            throw new Error('End date must be provided');
        }
        if (!isValidDate(end)) {
            throw new Error('Invalid end date');
        }
        // Convert the start and end date strings to Date objects
        // const startDate = new Date(start);
        const endDate = new Date(end);

        // Get the current date
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Reset time to the start of the da
        endDate.setHours(0, 0, 0, 0); // Reset time to the start of the da
        // Check if the current date is past the end date
        return currentDate > endDate;
    };

    const handleReUploadBannerClick = (banner) => {
        setPopupVisibleBannerReUpload(true);
        
        setBannerReUpload(prevState => ({
            id: banner.id,
            bannerOrder: banner.bannerOrder,
            end: new Date(banner.end).toISOString().split('T')[0],
            start: new Date(banner.start).toISOString().split('T')[0]
        }));
        //setBannerReUpload(banner);
    }

    // Handle change for re upload banner state
    const handleReUploadBannerChange = (event) => {
        const { name, value } = event.target;
        let stateValue;
        if((name === 'bannerOrder')){
            stateValue = value > 0 ? value : 1;
        }else{
            stateValue = value;
        }
        setBannerReUpload(prevState => ({
            ...prevState,
            [name]: stateValue
        }));
    };
    const handleReUploadBanner = () => {
        if(new Date(bannerReUpload.end) < new Date(bannerReUpload.start) || !(new Date(bannerReUpload.start) >= new Date(currentDate))){
            setIsValidRequest(false);
            // console.log(isValidRequest, bannerReUpload.end, bannerReUpload.start, new Date(bannerReUpload.end) < new Date(bannerReUpload.start), bannerReUpload.start >= currentDate);
        }else{
            setIsValidRequest(true);
            setPopupVisibleBannerReUpload(false);
            setBannerReUpload(false);
            reUploadlBanner(bannerReUpload);
        }
        // console.log(new Date(bannerReUpload.end) < new Date(bannerReUpload.start) , !(new Date(bannerReUpload.start) >= new Date(currentDate)));
    }

    const handleBannerOrderChange = (bannerOrder) => {
        if(bannerOrder > 0){
            setBannerOrderr(bannerOrder);
        }else{
            setBannerOrderr(1);
        }
    }

    const handleUpdateUrlClick = (banner) => {
        setPopupVisibleBannerUrlUpdate(true);
        setBannerId(banner.id);
        setBannerUrl(banner.bannerUrl || '');
        setTargetBlank(banner.targetBlank || false);
    };

    const handleBannerUrlChange = (event) => {
        setBannerUrl(event.target.value);
    };

    const handleTargetBlankChange = (event) => {
        setTargetBlank(event.target.checked);
    };

    const handleBannerUrlUpdate = async () => {  
        await updateBannerUrl({ id: bannerId, bannerUrl, targetBlank });
        setPopupVisibleBannerUrlUpdate(false);
    };

    useEffect(()=> {
        if(isBannerUpdating){
            setPopupVisible(false);
        }
    },[isBannerUpdating])
    
    useEffect(()=> {
        if(isBannerTracksUpdating === false){
            setBannerWidgetHeadLabel("All Banner");
        }
    },[isBannerTracksUpdating]);

    const handleTrackUpdateClick = async(banner) => {
        setIsBannerTracksUpdatingInit(true);
        await resetUpdateBannerTrack();
        await fetchBannerTracks({bannerId: banner.id});
        setBannerTracksUpdating(banner);
        setIsBannerTracksUpdating(true);
        setIsBannerTracksUpdatingInit(false);
        setBannerWidgetHeadLabel("Update banner tracks");
    };

    return (
        <>
        { isBannerTracksUpdating && <UpdateBanner setIsBannerTracksUpdating={setIsBannerTracksUpdating} banner={bannerTracksUpdating}/> }
            { isBannerTracksUpdating === false && popupVisibleBannerReUpload &&
                <div className="popup-container banner-order-change banner-re-upload">
                    <div className='flexAlignment'>
                        <h2 className='secTitle mb-5'>Banner Re-upload</h2>
                            {isValidRequest === false && <>
                                <p className='text-danger m--regular-font-size-lg3 m--font-bolder'>Please select a correct start & end date.</p>
                            </>}
                        <div className='row'>
                            <div className='col-6'>
                                <label className="col-12 padding-remove text-left control-label">Start Date</label>
                                <input type="date" placeholder='Banner Order' className="banner-order" name="start" min={currentDate} max={bannerReUpload.end > currentDate ? bannerReUpload.end : '3024-08-23'}  value={bannerReUpload.start} onChange={(e) => handleReUploadBannerChange(e)}/>
                            </div>
                            <div className='col-6'>
                                <label className="col-12 padding-remove text-left control-label">End Date</label>
                                <input type="date" placeholder='Banner Order' className="banner-order" name="end" min={bannerReUpload.start} value={bannerReUpload.end} onChange={(e) => handleReUploadBannerChange(e)}/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 mb-4'>
                                <label className="col-12 padding-remove text-left control-label">Order</label>
                                <input type="number" placeholder='Banner Order' className="banner-order" min={1} name="bannerOrder" value={bannerReUpload.bannerOrder} onChange={(e) => handleReUploadBannerChange(e)}/>
                            </div>
                        </div>
                        <button className='btn btn-success' onClick={handleReUploadBanner}>Re-upload Banner</button>
                        <div className='closeIcon' onClick={handleCloseModalClick}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            }
        <div className="p-4 m-3 bannerList">
            {isBannerTracksUpdating === false && popupVisible &&
                <div className="popup-container banner-order-change">
                    <div className='flexAlignment'>
                        <h2 className='secTitle mb-4'>Banner Order</h2>
                        <input type="number" placeholder='Banner Order' className="banner-order" step={1} min={1} value={bannerOrderr} onChange={(e) => handleBannerOrderChange(e.target.value)}/>
                        <button className='btn btn-success' onClick={handleBannerOrderUpdateClick}>Update Order</button>
                        <div className='closeIcon' onClick={handleCloseModalClick}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            }
             {!isBannerUpdating && popupVisibleBannerUrlUpdate && 
            // {popupVisibleBannerUrlUpdate &&
                <div className="popup-container banner-order-change popupBannerWrap">   
                    <div className='flexAlignment'>
                        <h2 className='secTitle mb-5'>Update Banner URL</h2>
                        <div className='row'>
                            <div className='col-12 mb-4 text-left'>
                                <label className="padding-remove text-left control-label">Banner URL</label>
                                <input type="text" placeholder='Enter banner URL' className="banner-url w-100 mx-0 mb-3" value={bannerUrl} onChange={handleBannerUrlChange} />
                            </div>
                            <div className='col-12 mb-5 text-left'>
                                <label className="padding-remove text-left control-label mb-0 fontNormal">Open in New Tab</label>
                                <input type="checkbox" className='checkBox' checked={targetBlank} onChange={handleTargetBlankChange} />
                            </div>
                            <div className='col-12 btnGap'>
                                <button onClick={handleBannerUrlUpdate} className='btn btn-primary'>Update URL</button>
                                <button onClick={handleCloseModalClick} className='btn btn-secondary w-100'>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
             {isBannerTracksUpdating === false && (isBannerUpdating || isBannerReAdding || isBannerTracksUpdatingInit) && <>
                <div className='loading'>
                    <div className='loader'>
                        <BounceLoader loading={true} size={60} color='#34bfa3'/>
                        <span className="loading-text">Loading...</span>
                    </div>
                </div>
            </>}
            {isBannerTracksUpdating === false && isBannerReAdded && <>
                    <div className='loading'>
                        <div className='loader'>
                            <span className="loading-text banner-reupload-msg">{isBannerReAddedd}</span>
                        </div>
                    </div>
                </>}
            {isBannerTracksUpdating === false && bannerUpdating && isBannerUpdatingRes && isBannerUpdatingRes.messageColor && isBannerUpdatingRes.message && (
                <div className='loading'>
                    <div className='loader message'>
                        <span className={`${isBannerUpdatingRes.messageColor}-text`}>{isBannerUpdatingRes.message}</span>
                    </div>
                </div>
            )}
             {isBannerTracksUpdating === false && <>
            <table className='table m-table m-table--head-bg-success'>
                <thead>
                    <tr>
                        <th style={{width:'40px'}}>Id</th>
                        <th style={{width:'250px'}}>Title</th>
                        <th style={{width:'150px'}}>Start</th>
                        <th style={{width:'150px'}}>End</th>
                        <th style={{width:'700px'}}>Tracks/URL</th>
                        <th style={{width:'400px'}}>Banner</th>
                        <th style={{width:'100px'}}>Order</th>
                        <th style={{width:'250px'}}>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {(isBannerFetching === true) &&
                        <>
                            <tr>
                                <th className="text-center" colSpan={8} >
                                    Loading....
                                </th>
                            </tr>
                        </>
                    }
                    {(allBanners.length === 0 && isBannerFetching === false) &&
                        <>
                            <tr>
                                <th className="text-center" colSpan={8} >
                                    No banner found.
                                </th>
                            </tr>
                        </>
                    }
                    {allBanners && isBannerFetching === false && allBanners.length > 0 && allBanners.map((b) => (
                        <tr key={b.id}>
                            <td style={{width:'40px'}}>{b.id}</td>
                            <td style={{width:'250px'}}>{b.title}</td>
                            <td style={{width:'150px'}}>{new Date(b.start).toISOString().split('T')[0]}</td>
                            <td style={{width:'150px'}}>{new Date(b.end).toISOString().split('T')[0]}</td>
                            <td style={{ width: '700px' }}>
                               {b.bannerType === 'tracks' && b.songNames && typeof b.songNames === 'string' && b.songNames.length > 0 && (
                                    <ol style={{paddingLeft: '13px'}}>
                                        {b.songNames.split(',').map((track, index) => (
                                            <li key={index}>{track.trim()}</li>
                                        ))}
                                    </ol>
                                )}
                                {b.bannerType === 'url' && b.bannerUrl && typeof b.bannerUrl === 'string' && (
                                    <span>{b.bannerUrl.trim()}</span>
                                 )}
                            </td>
                            <td style={{width:'400px'}}><img src={b.banner} alt=""/></td>
                            <td style={{width:'100px'}}>{b.bannerOrder}</td>
                            <td style={{width:'250px'}}>
                                {isExpired(b) === false && <>
                                    <button className={`btn m-1 btn-${b.status ? 'danger' : 'success'} pl-2 pr-2 pt-1 pb-1  btn-sm`} onClick={() => handleSwitcherClick(b.id)}>
                                        {b.status ? 'Hide ' : 'Show'}
                                    </button>
                                    <button className='btn m-1 btn-warning pl-2 pr-2 pt-1 pb-1 btn-sm' onClick={() => handleOrderChangeClick({id: b.id, order: b.bannerOrder})}>
                                        Update Order
                                    </button>
                                </>}
                                {isExpired(b) === true && <>
                                    <button className='btn m-1 btn-primary pl-2 pr-2 pt-1 pb-1 btn-sm' onClick={() => handleReUploadBannerClick(b)}>
                                        Re Upload
                                    </button>
                                </>}                                
                                {b.bannerType === 'url' && (
                                        <button className='btn m-1 btn-info pl-2 pr-2 pt-1 pb-1 btn-sm' onClick={() => handleUpdateUrlClick(b)}>
                                            Update URL
                                        </button>
                                    )}
                                {b.bannerType === 'tracks' && <>
                                        <button className='btn m-1 btn-warning pl-2 pr-2 pt-1 pb-1 btn-sm' onClick={() => handleTrackUpdateClick({id: b.id, tracks: b.tracks})}>
                                            Update Tracks
                                        </button>
                                </>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </>}
            {isBannerTracksUpdating === false && bannersCount > 0 && (
        
            <div key="paginator" className="row pl-xl-4 pr-xl-4">
                <div key="info" className="col-sm-12 col-md-5">
                    {/* <div className="dataTables_info" id="m_table_1_info" role="status" aria-live="polite">
                        Showing  {Number.parseInt(offset) + 1} to {topRange < bannersCount ? topRange : bannersCount} of {bannersCount} entries
                    </div> */}
                </div>
                <div key="paginator-controls" className="col-sm-12 col-md-7 d-flex flex-row align-items-center justify-content-end">
                    <Paginator
                        key="paginator"
                        count={bannersCount}
                        offset={Number.parseInt(offset)}
                        limit={Number.parseInt(10)}
                        // options={options}
                        offsetOnChange={(value) => offsetOnChange(value)}
                        // limitOnChange={limitOnChange}
                    />
                </div>
            </div>
        )}
        </div>
        </>
    );
}
